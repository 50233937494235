import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Button, Form, Card, Input, Spin, Collapse, Tooltip, notification, Modal } from "antd";
import { SaveOutlined, PlusCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import AnexoIEstablecimientoModal from "./AnexoIEstablecimientoModal";
import AnexoIEmpresasTercerizadasModal from "./AnexoIEmpresasTercerizadasModal";
import AnexoIEstablecimientosDeclaradosTable from "../view/AnexoIEstablecimientosDeclaradosTable";
import AnexoIEmpresasTercerizadasTable from "../view/AnexoIEmpresasTercerizadasTable";
import AnexosIApi from "../../api/AnexosIApi";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import ReferenciasApi from "../../../referencias/api/ReferenciasApi";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";

const FormItem = Form.Item;
const { Panel } = Collapse;

const AnexoIForm = props => {
  const history = useHistory();
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const [examenesMedicos, setExamenesMedicos] = useState([]);
  const [establecimientosDeclarados, setEstablecimientosDeclarados] = useState([]);
  const [unidadesFrecuencia, setUnidadesFrecuencia] = useState([]);
  const [medidasPreventivas, setMedidasPreventivas] = useState([]);
  const [elementosProteccion, setElementosProteccion] = useState([]);
  const [sustancias, setSustancias] = useState([]);
  const [empresasTercerizadas, setEmpresasTercerizadas] = useState([]);
  const [showEstablecimientoModal, setShowEstablecimientoModal] = useState(false);
  const [showEmpresaTercerizadaModal, setShowEmpresaTercerizadaModal] = useState(false);
  const [selectedEstablecimientoDeclarado, setSelectedEstablecimientoDeclarado] = useState(undefined);
  const [selectedEmpresaTercerizada, setSelectedEmpresaTercerizada] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();
  const permisosUsuario = AuthenticationHelper.getTipoUsuario() !== 'Operador';
  let datos = {
    "Cuit": "",
    "EstablecimientosDeclarados": [],
    "Puestos": [],
    "Sectores": [],
    "Contratistas": [],
    "Responsables": [],
    "EmpresasTercerizadas": []
  }
  let eliminarDatos = false;

  useEffect(() => {
    datos.Cuit = props.match.params.cuit;
    AuthenticationHelper.storeDatosRemove(datos);
    EstablecimientosApi.GetEmpresasByCuit(props.match.params.cuit).then((response) => { setEstablecimientosEmpresa(response); });
    ReferenciasApi.GetTabla("SVCC.TSUSTANCIAS").then(response => { setSustancias(response); });
    ReferenciasApi.GetTabla("SVCC.EXAMENMEDICO").then(response => { setExamenesMedicos(response); });
    ReferenciasApi.GetTabla("SVCC.TMEDIDASPREVENTIVAS").then(response => { setMedidasPreventivas(response); });
    ReferenciasApi.GetTabla("SVCC.TELEMENTOS_PROTECCION_PERSONAL").then(response => { setElementosProteccion(response); });
    ReferenciasApi.GetTabla("SVCC.TFRECUENCIA").then(response => { setUnidadesFrecuencia(response); });

    if (props.location.pathname.indexOf('edit') > -1 && !props.location.anexoi) {
      history.goBack();
    }
    if (props.location.anexoi) {
      setEstablecimientosDeclarados(props.location.anexoi.EstablecimientosDeclarados);
      setEmpresasTercerizadas(props.location.anexoi.EmpresasTercerizadas);
    }
  }, []);

  const onFinish = values => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: <span>¿Está seguro de querer guardar el Anexo I?</span>,
      onOk() {
        setWorking(true);
        let data = {
          //id: props.match.params.id, 
          ...values,
          EstablecimientosDeclarados: establecimientosDeclarados,
          EmpresasTercerizadas: empresasTercerizadas
        }
        if (!props.location.anexoi) {
          AnexosIApi.Save(data)
            .then(response => then(response))
            .catch(error => { setWorking(false); });
        }
        else {
          datos = AuthenticationHelper.getDatosRemove();

          Object.keys(datos).forEach(key => {
            if (Array.isArray(datos[key]) && datos[key].length > 0) {
              eliminarDatos = true;
            }
          });

          if (eliminarDatos) {
            AnexosIApi.Delete(datos)
            .then(response => then(response))
            .catch(error => { setWorking(false); });
          }

          AnexosIApi.Edit(data)
            .then(response => then(response))
            .catch(error => { setWorking(false); });
        }
      },
      onCancel() { },
    });

    const then = response => {
      setWorking(false);
      if (response.status == 200) {
        notification.success({ 
          message: 'Éxito', 
          description: 'Anexo I guardado', 
          placement: 'bottomRight' 
        });

        setTimeout(() => {
          AuthenticationHelper.removeDatosRemove();
          history.goBack();
        }, 400);
      } else {
        notification.error({ message: 'Error', description: '¡No se pudo guardar el Anexo I!', placement: 'bottomRight' });
        notification.error({ message: 'Error', description: response, placement: 'bottomRight' });
      }
    }
  };

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title={`${props.location.anexoi ? '' : 'Nuevo'} Anexo I`}
        //subTitle="Formulario"
        onBack={() => history.goBack()}
      />
      <div style={{ padding: 16, minHeight: 690 }}>
        <Spin spinning={working}>
          <Card>
            <Form
              {...FormConfig.DefaultLayout}
              form={form}
              name="formulario"
              onFinish={onFinish}
              initialValues={{ ...props.location.anexoi }}
              scrollToFirstError
            >
              <FormItem name="Cuit" label="Cuit" rules={FormConfig.DefaultRules} initialValue={props.location.puedeEditar}>
                <Input disabled />
              </FormItem>

              {props.location.anexoi?.IdTipoOrganismo ? <FormItem name="IdTipoOrganismo" label="Tipo Organismo" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              {props.location.anexoi?.IdOrganismo ? <FormItem name="IdOrganismo" label="Organismo" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}


              <Collapse style={{ marginBottom: 24 }}>
                {
                  permisosUsuario && props.location.puedeEditar == true ?
                    <>
                      <Panel header="Establecimientos Declarados" key="1"
                        extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                          onClick={event => {
                            event.stopPropagation();
                            setSelectedEstablecimientoDeclarado(undefined);
                            setShowEstablecimientoModal(true);
                          }}
                        /></Tooltip>}
                      >
                        {establecimientosDeclarados.length > 0
                          ? <div style={{ overflow: 'auto', width: '100%' }}>
                            <AnexoIEstablecimientosDeclaradosTable formMode
                              cuit={props.match.params.cuit}
                              Presentacion={props.location.presentacion}
                              EstablecimientosDeclarados={establecimientosDeclarados}
                              EstablecimientosEmpresa={establecimientosEmpresa}
                              Sustancias={sustancias}
                              medidasPreventivas={medidasPreventivas}
                              elementosProteccion={elementosProteccion}
                              unidadesFrecuencia={unidadesFrecuencia}
                              ExamenesMedicos={examenesMedicos}
                              Trabajadores={props.location.Nomina.Trabajadores}
                              SustanciasDeclaradas={props.location.anexov.Sustancias}
                              puedeEditar={props.location.puedeEditar}
                              onRemove={record => {
                                setEstablecimientosDeclarados([...establecimientosDeclarados.filter(item => (item.IdEstablecimientoDeclarado !== record.IdEstablecimientoDeclarado))]);
                                datos = AuthenticationHelper.getDatosRemove();
                                datos.EstablecimientosDeclarados.push(record.IdEstablecimientoDeclarado);
                                AuthenticationHelper.storeDatosRemove(datos);
                              }}
                              onEdit={record => {
                                setSelectedEstablecimientoDeclarado(record);
                                setShowEstablecimientoModal(true);
                              }}
                            /></div>
                          : ''}
                      </Panel>
                    </> :
                    <>
                      <Panel header="Establecimientos Declarados" key="1">
                        {establecimientosDeclarados.length > 0
                          ? <div style={{ overflow: 'auto', width: '100%' }}>
                            <AnexoIEstablecimientosDeclaradosTable formMode
                              cuit={props.match.params.cuit}
                              Presentacion={props.location.presentacion}
                              EstablecimientosDeclarados={establecimientosDeclarados}
                              EstablecimientosEmpresa={establecimientosEmpresa}
                              Sustancias={sustancias}
                              medidasPreventivas={medidasPreventivas}
                              elementosProteccion={elementosProteccion}
                              unidadesFrecuencia={unidadesFrecuencia}
                              ExamenesMedicos={examenesMedicos}
                              Trabajadores={props.location.Nomina.Trabajadores}
                              SustanciasDeclaradas={props.location.anexov.Sustancias}
                              puedeEditar={props.location.puedeEditar}
                              onRemove={record => {
                                setEstablecimientosDeclarados([...establecimientosDeclarados.filter(item => (item.IdEstablecimientoDeclarado !== record.IdEstablecimientoDeclarado))]);
                                datos = AuthenticationHelper.getDatosRemove();
                                datos.EstablecimientosDeclarados.push(record.IdEstablecimientoDeclarado);
                                AuthenticationHelper.storeDatosRemove(datos);
                              }}
                              onEdit={record => {
                                setSelectedEstablecimientoDeclarado(record);
                                setShowEstablecimientoModal(true);
                              }}
                            /></div>
                          : ''}
                      </Panel>
                    </>
                }

                {
                  permisosUsuario && props.location.puedeEditar == true ?
                    <>
                      <Panel header="Empresas Tercerizada" key="2"
                        extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                          onClick={event => {
                            event.stopPropagation();
                            setSelectedEmpresaTercerizada(undefined);
                            setShowEmpresaTercerizadaModal(true);
                          }}
                        /></Tooltip>}
                      >
                        {empresasTercerizadas.length > 0
                          ? <div style={{ overflow: 'auto', width: '100%' }}>
                            <AnexoIEmpresasTercerizadasTable formMode
                              cuit={props.match.params.cuit}
                              EmpresasTercerizadas={empresasTercerizadas}
                              puedeEditar={props.location.puedeEditar}
                              onRemove={record => {
                                setEmpresasTercerizadas([...empresasTercerizadas.filter(item => (item.IdEmpresaTercerizada !== record.IdEmpresaTercerizada))]);
                                datos = AuthenticationHelper.getDatosRemove();
                                datos.EmpresasTercerizadas.push(record.IdEmpresaTercerizada);
                                AuthenticationHelper.storeDatosRemove(datos);
                              }}
                              onEdit={record => {
                                setSelectedEmpresaTercerizada(record);
                                setShowEmpresaTercerizadaModal(true);
                              }}
                            /></div>
                          : ''}
                      </Panel>
                    </> :
                    <>
                      <Panel header="Empresas Tercerizada" key="2">
                        {empresasTercerizadas.length > 0
                          ? <div style={{ overflow: 'auto', width: '100%' }}>
                            <AnexoIEmpresasTercerizadasTable formMode
                              cuit={props.match.params.cuit}
                              EmpresasTercerizadas={empresasTercerizadas}
                              puedeEditar={props.location.puedeEditar}
                              onRemove={record => {
                                setEmpresasTercerizadas([...empresasTercerizadas.filter(item => (item.IdEmpresaTercerizada !== record.IdEmpresaTercerizada))]);
                                datos = AuthenticationHelper.getDatosRemove();
                                datos.EmpresasTercerizadas.push(record.IdEmpresaTercerizada);
                                AuthenticationHelper.storeDatosRemove(datos);
                              }}
                              onEdit={record => {
                                setSelectedEmpresaTercerizada(record);
                                setShowEmpresaTercerizadaModal(true);
                              }}
                            /></div>
                          : ''}
                      </Panel>
                    </>
                }

              </Collapse>

              <FormItem wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                {
                  permisosUsuario ?
                    <>
                      <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
                    </> : null
                }
              </FormItem>

            </Form>
            {
              showEstablecimientoModal ?
                <>
                  <AnexoIEstablecimientoModal
                    cuit={props.match.params.cuit}
                    establecimiento={selectedEstablecimientoDeclarado}
                    visible={showEstablecimientoModal}
                    onOk={establecimiento => {
                      let arr = [...establecimientosDeclarados];
                      let idx = arr.findIndex(x => x.Hash ? x.Hash === establecimiento.Hash : x.IdEstablecimientoDeclarado === establecimiento.IdEstablecimientoDeclarado);
                      if (idx !== -1) { arr[idx] = establecimiento; }
                      else { arr.push(establecimiento); }
                      setEstablecimientosDeclarados([...arr]);
                      setShowEstablecimientoModal(false);
                    }}
                    onCancel={() => setShowEstablecimientoModal(false)}
                  />
                </> : null
            }

            {
              showEmpresaTercerizadaModal ?
                <>
                  <AnexoIEmpresasTercerizadasModal
                    cuit={props.match.params.cuit}
                    empresa={selectedEmpresaTercerizada}
                    visible={showEmpresaTercerizadaModal}
                    onOk={empresa => {
                      let arr = [...empresasTercerizadas];
                      let idx = arr.findIndex(x => x.Hash ? x.Hash === empresa.Hash : x.IdEmpresaTercerizada === empresa.IdEmpresaTercerizada);
                      if (idx !== -1) { arr[idx] = empresa; }
                      else { arr.push(empresa); }
                      setEmpresasTercerizadas([...arr]);
                      setShowEmpresaTercerizadaModal(false);
                    }}
                    onCancel={() => setShowEmpresaTercerizadaModal(false)}
                  />
                </> : null
            }

          </Card>
        </Spin>
      </div>
    </div>
  );
}

export default AnexoIForm;