import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Button, Form, Card, Input, Spin, Select, DatePicker, notification } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import PresentacionesApi from "../../api/PresentacionesApi";
import ReferenciasApi from "../../../referencias/api/ReferenciasApi";
import ReferenciaSelectFormItem from "../../../../shared/components/referenciaSelectFormItem";
import CuitFormItem from '../../../../shared/components/cuitFormItem';
import TipoPresentacionSelectFormItem from "../../../../shared/components/tipoPresentacionSelectFormItem";
import CampoObligatorio from "../../../../shared/components/campoObligatorio";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const FormItem = Form.Item;
const { Option } = Select;

const PresentacionForm = props => {
  const history = useHistory();
  const [motivos, setMotivos] = useState([]);
  const [programasMuestra, setProgramasMuestra] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();
  const [tiposPresentacion, setTiposPresentacion] = useState();
  const [presentacionNueva, setPresentacionNueva] = useState(false);
  let tipoUsuario = AuthenticationHelper.getTipoUsuario();
  let presentacionUsuario = JSON.parse(AuthenticationHelper.getPresentacionUsuario());

  useEffect(() => {
    loadTablas();
  }, []);

  const loadTablas = () => {
    ReferenciasApi.GetTabla("SVCC.TMOTIVOS_PRESENTACIONES").then(response => {
      setMotivos(response);
    });
    ReferenciasApi.GetTabla("MATRIZUNICA.PROGRAMASMUESTRAS").then(response => { setProgramasMuestra(response); });
    PresentacionesApi.GetTiposPresentacion().then(response => { setTiposPresentacion(response); });
  }

  const nuevaPresentacion = cuit => {
    let cuitPresentacion = cuit.target.form[0].value;
    if (cuitPresentacion.length == 11) {
      setWorking(true);
      PresentacionesApi.GetByCuit(cuit.target.form[0].value)
        .then(response => {
          if (response.length == 0) {
            setPresentacionNueva(true);
          } else {
            setPresentacionNueva(false);
          }
          setWorking(false);
        })
        .catch((error) => { setWorking(false); });
    }
  }

  const onFinish = values => {
    setWorking(true);
    let Id_TipoPresentacion = 1;
    let Empleador = "_";
    if (values.Id_TipoPresentacion !== undefined && Empleador !== undefined ) { 
      Id_TipoPresentacion = values.Id_TipoPresentacion; 
      Empleador = values.Empleador;
    }
    let data = {
      ...values,
    }
    PresentacionesApi.Iniciar(Empleador, Id_TipoPresentacion, data)
    .then((response) => {
        setWorking(false);
        if (response.status == 200) {
          notification.success({ message: 'Exito', description: 'Presentación iniciada', placement: 'bottomRight' });
          setTimeout(() => {
            AuthenticationHelper.removeDatosRemove();
            history.goBack();
          }, 400);
        } else {
          notification.error({ message: 'Error', description: '¡No se pudo inciar la presentacion!', placement: 'bottomRight' });
          notification.error({ message: 'Error', description: response, placement: 'bottomRight' });
        }
      })
      .catch((error) => {
        setWorking(false);
      });
  };

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title={`${'Iniciar Presentación'}`}
        //subTitle="Formulario"
        onBack={() => history.goBack()}
      />
      <div style={{ padding: 20 }}>
        <Spin spinning={working}>
          <Card>
            <Form
              {...FormConfig.DefaultLayout}
              form={form}
              name="formulario"
              initialValues={tipoUsuario == "Administrador Presentacion" ? { Cuit: presentacionUsuario[0].Cuit } : undefined}
              onFinish={onFinish}
              onChange={nuevaPresentacion}

              scrollToFirstError
            >
              {
                tipoUsuario == "Administrador Presentacion" ?
                  <>
                    <FormItem name="Cuit" label="Cuit" rules={FormConfig.DefaultRules}>
                      <Input disabled />
                    </FormItem>
                  </> :
                  <CuitFormItem name="Cuit" label="Cuit" rules={[...FormConfig.DefaultRules]} />
              }
              {
                motivos.length > 0 ?
                  <>
                    {
                      presentacionNueva ?
                        <>
                          <FormItem name="Empleador" label="Nombre de Empleador" rules={FormConfig.DefaultRules}>
                            <Input />
                          </FormItem>

                          <ReferenciaSelectFormItem name="IdMotivo" label="IdMotivo"
                            rules={FormConfig.DefaultRules}
                            tabla={[motivos[0]]}
                          />
                        </> :
                        <ReferenciaSelectFormItem name="IdMotivo" label="IdMotivo"
                          rules={FormConfig.DefaultRules}
                          tabla={[motivos[1]]}
                        />
                    }
                  </> : null
              }


              <FormItem name="IdProgramaMuestra" label="Programa Muestra"
                rules={[{ required: true, message: 'Por favor ingrese un valor', whitespace: true }]}
              >
                <Select showSearch filterOption={FormConfig.DefaultSelectFilter}>
                  {programasMuestra.map(programa => {
                    return <Option key={programa.CODIGOMUESTRA} value={programa.CODIGOMUESTRA}>{
                      `Cod Programa: ${programa.CODIGOPROGRAMA} - Cod Muestra: ${programa.CODIGOMUESTRA} - Nro Muestra: ${programa.NROMUESTRA}`}
                    </Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="FechaPresentacionEmpleador" label="Fecha Presentacion Empleador" rules={FormConfig.DefaultRules}>
                <DatePicker style={{ width: '100%' }} format={"DD/MM/YYYY HH:mm:ss"} />
              </FormItem>

              <FormItem name="Observaciones" label="Observaciones" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              {
                presentacionNueva ?
                  <>
                    <TipoPresentacionSelectFormItem name="Id_TipoPresentacion" label="Tipo de Presentacion"
                      tabla={tiposPresentacion} rules={FormConfig.DefaultRules}
                    />
                  </> : null
              }

              <CampoObligatorio />

              <FormItem wrapperCol={{ span: 16, offset: 8 }}>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
              </FormItem>
            </Form>
          </Card>
        </Spin>
      </div>
    </div>
  );
}

export default PresentacionForm;